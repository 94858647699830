<template>
	<a-spin :tip="spinnerLoaderLabel" size="large" :spinning="spinnerLoader">
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-md-4 pt10">
						<h5>
							<strong>Ingresos y egresos</strong>
						</h5>
					</div>
					<div class="col-md-8 text-right">
						<a-button class="btn btn-success ml5" icon="reload" @click="initModule" />
					</div>
					<div class="col-md-12">
						<hr class="hrText m0" data-content="♦" />
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<a-tabs default-active-key="1">
							<a-tab-pane key="1" tab="Vista general" force-render>
								<!-- <div class="row">
									<div class="col-md-12">
										<h3>Ingresos</h3>
									</div>
									<div class="col-md-3">
										<div class="card border-0" style="background-color: #000000">
											<div class="card-body">
												<div class="text-white font-weight-bold">
													<div class="font-size-21">General</div>
													<div class="font-size-18">{{ numeral(generalCashFlow.global.general.in).format('$0,0.00') }}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-3">
										<div class="card border-0" style="background-color: #303030">
											<div class="card-body">
												<div class="text-white font-weight-bold">
													<div class="font-size-21">Efectivo</div>
													<div class="font-size-18">{{ numeral(generalCashFlow.global.cash.in).format('$0,0.00') }}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-3">
										<div class="card border-0" style="background-color: #5e5e5e">
											<div class="card-body">
												<div class="text-white font-weight-bold">
													<div class="font-size-21">Bancos</div>
													<div class="font-size-18">{{ numeral(generalCashFlow.global.bank.in).format('$0,0.00') }}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-3">
										<div class="card border-0" style="background-color: #919191">
											<div class="card-body">
												<div class="text-white font-weight-bold">
													<div class="font-size-21">MercadoPago</div>
													<div class="font-size-18">{{ numeral(generalCashFlow.global.mercadopago.in).format('$0,0.00') }}</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="row">
									<div class="col-md-12">
										<h3>Egresos</h3>
									</div>
									<div class="col-md-3">
										<div class="card border-0" style="background-color: #000000">
											<div class="card-body">
												<div class="text-white font-weight-bold">
													<div class="font-size-21">General</div>
													<div class="font-size-18">{{ numeral(generalCashFlow.global.general.out).format('$0,0.00') }}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-3">
										<div class="card border-0" style="background-color: #303030">
											<div class="card-body">
												<div class="text-white font-weight-bold">
													<div class="font-size-21">Efectivo</div>
													<div class="font-size-18">{{ numeral(generalCashFlow.global.cash.out).format('$0,0.00') }}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-3">
										<div class="card border-0" style="background-color: #5e5e5e">
											<div class="card-body">
												<div class="text-white font-weight-bold">
													<div class="font-size-21">Bancos</div>
													<div class="font-size-18">{{ numeral(generalCashFlow.global.bank.out).format('$0,0.00') }}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-3">
										<div class="card border-0" style="background-color: #919191">
											<div class="card-body">
												<div class="text-white font-weight-bold">
													<div class="font-size-21">MercadoPago</div>
													<div class="font-size-18">{{ numeral(generalCashFlow.global.mercadopago.out).format('$0,0.00') }}</div>
												</div>
											</div>
										</div>
									</div>
								</div> -->
								<div class="row">
									<div class="col-md-12">
										<h3>Saldos</h3>
									</div>
									<div class="col-md-3">
										<div class="card border-0" style="background-color: #000000">
											<div class="card-body">
												<div class="text-white font-weight-bold">
													<div class="font-size-21">General</div>
													<div class="font-size-18">{{ numeral(generalCashFlow.global.general.in - generalCashFlow.global.general.out).format('$0,0.00') }}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-3">
										<div class="card border-0" style="background-color: #303030">
											<div class="card-body">
												<div class="text-white font-weight-bold">
													<div class="font-size-21">Efectivo</div>
													<div class="font-size-18">{{ numeral(generalCashFlow.global.cash.in - generalCashFlow.global.cash.out).format('$0,0.00') }}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-3">
										<div class="card border-0" style="background-color: #5e5e5e">
											<div class="card-body">
												<div class="text-white font-weight-bold">
													<div class="font-size-21">Bancos</div>
													<div class="font-size-18">{{ numeral(generalCashFlow.global.bank.in - generalCashFlow.global.bank.out).format('$0,0.00') }}</div>
												</div>
											</div>
										</div>
									</div>
									<div class="col-md-3">
										<div class="card border-0" style="background-color: #919191">
											<div class="card-body">
												<div class="text-white font-weight-bold">
													<div class="font-size-21">MercadoPago</div>
													<div class="font-size-18">{{ numeral(generalCashFlow.global.mercadopago.in - generalCashFlow.global.mercadopago.out).format('$0,0.00') }}</div>
												</div>
											</div>
										</div>
									</div>
									<!-- <div class="col-12 text-right">
										<download-csv class="btn btn-default" :data="generalCashFlow.transactions" name="cashFlow.csv"> DESCARGAR REPORTE </download-csv>
									</div> -->
								</div>
							</a-tab-pane>
							<!-- <a-tab-pane key="10" tab="Vista general" force-render>
								<a-range-picker style="width: 100%" @change="initModule" v-model="defaultDate" :default-value="defaultDate" :format="dateFormat" />
								<div class="row">
									<div class="col-md-4 text-center">
										Ingresos <br />
										<b>{{ numeral(cashFlowList.general.in).format('$0,0.00') }}</b>
									</div>
									<div class="col-md-4 text-center">
										Egresos <br />
										<b>{{ numeral(cashFlowList.general.out).format('$0,0.00') }}</b>
									</div>
									<div class="col-md-4 text-center">
										Saldo <br />
										<b>{{ numeral(cashFlowList.general.in - cashFlowList.general.out).format('$0,0.00') }}</b>
									</div>
									<div class="col-md-12 pt10">
										<a-table :columns="columns" :dataSource="cashFlowList.transactions" rowKey="id">
											<div slot="created_at" slot-scope="record">{{ record.created_at }} hrs.</div>
											<div slot="description" slot-scope="record">
												<a-tag :color="record.type == 'in' ? 'green' : 'red'">
													{{ record.id }}
												</a-tag>
											</div>
											<div slot="cash_in" slot-scope="record">
												<div v-if="record.type == 'in'">
													{{ numeral(record.amount).format('$0,0.00') }}
												</div>
												<div v-else>-</div>
											</div>
											<div slot="cash_out" slot-scope="record">
												<div v-if="record.type == 'out'">
													{{ numeral(record.amount).format('$0,0.00') }}
												</div>
												<div v-else>-</div>
											</div>
										</a-table>
									</div>
								</div>
							</a-tab-pane>
							<a-tab-pane key="2" tab="Efectivo" force-render>
								<div class="row">
									<div class="col-md-4 text-center">
										Ingresos <br />
										<b>{{ numeral(cashFlowList.cash.in).format('$0,0.00') }}</b>
									</div>
									<div class="col-md-4 text-center">
										Egresos <br />
										<b>{{ numeral(cashFlowList.cash.out).format('$0,0.00') }}</b>
									</div>
									<div class="col-md-4 text-center">
										Saldo <br />
										<b>{{ numeral(cashFlowList.cash.in - cashFlowList.cash.out).format('$0,0.00') }}</b>
									</div>
									<div class="col-md-12 pt10">
										<a-table :columns="columns" :dataSource="cashFlowList.transactions.filter((e) => e.payment_type == 'cash')" rowKey="id">
											<div slot="created_at" slot-scope="record">{{ record.created_at }} hrs.</div>
											<div slot="description" slot-scope="record">
												<a-tag :color="record.type == 'in' ? 'green' : 'red'">
													{{ record.id }}
												</a-tag>
											</div>
											<div slot="cash_in" slot-scope="record">
												<div v-if="record.type == 'in'">
													{{ numeral(record.amount).format('$0,0.00') }}
												</div>
												<div v-else>-</div>
											</div>
											<div slot="cash_out" slot-scope="record">
												<div v-if="record.type == 'out'">
													{{ numeral(record.amount).format('$0,0.00') }}
												</div>
												<div v-else>-</div>
											</div>
										</a-table>
									</div>
									<div class="col-12 text-right">
										<download-csv class="btn btn-default" :data="cashFlowList.transactions.filter((e) => e.payment_type == 'cash')" name="cashFlow_cash.csv"> DESCARGAR REPORTE </download-csv>
									</div>
								</div>
							</a-tab-pane>
							<a-tab-pane key="3" tab="Banco" force-render>
								<div class="row">
									<div class="col-md-4 text-center">
										Ingresos <br />
										<b>{{ numeral(cashFlowList.bank.in).format('$0,0.00') }}</b>
									</div>
									<div class="col-md-4 text-center">
										Egresos <br />
										<b>{{ numeral(cashFlowList.bank.out).format('$0,0.00') }}</b>
									</div>
									<div class="col-md-4 text-center">
										Saldo <br />
										<b>{{ numeral(cashFlowList.bank.in - cashFlowList.bank.out).format('$0,0.00') }}</b>
									</div>
									<div class="col-md-12 pt10">
										<a-table :columns="columns" :dataSource="cashFlowList.transactions.filter((e) => e.payment_type == 'bank')" rowKey="id">
											<div slot="created_at" slot-scope="record">{{ record.created_at }} hrs.</div>
											<div slot="description" slot-scope="record">
												<a-tag :color="record.type == 'in' ? 'green' : 'red'">
													{{ record.id }}
												</a-tag>
											</div>
											<div slot="cash_in" slot-scope="record">
												<div v-if="record.type == 'in'">
													{{ numeral(record.amount).format('$0,0.00') }}
												</div>
												<div v-else>-</div>
											</div>
											<div slot="cash_out" slot-scope="record">
												<div v-if="record.type == 'out'">
													{{ numeral(record.amount).format('$0,0.00') }}
												</div>
												<div v-else>-</div>
											</div>
										</a-table>
									</div>
									<div class="col-12 text-right">
										<download-csv class="btn btn-default" :data="cashFlowList.transactions.filter((e) => e.payment_type == 'digital')" name="cashFlow_digital.csv"> DESCARGAR REPORTE </download-csv>
									</div>
								</div>
							</a-tab-pane>
							<a-tab-pane key="4" tab="MercadoPago" force-render>
								<div class="row">
									<div class="col-md-4 text-center">
										Ingresos <br />
										<b>{{ numeral(cashFlowList.mercadopago.in).format('$0,0.00') }}</b>
									</div>
									<div class="col-md-4 text-center">
										Egresos <br />
										<b>{{ numeral(cashFlowList.mercadopago.out).format('$0,0.00') }}</b>
									</div>
									<div class="col-md-4 text-center">
										Saldo <br />
										<b>{{ numeral(cashFlowList.mercadopago.in - cashFlowList.mercadopago.out).format('$0,0.00') }}</b>
									</div>
									<div class="col-md-12 pt10">
										<a-table :columns="columns" :dataSource="cashFlowList.transactions.filter((e) => e.payment_type == 'mercadopago')" rowKey="id">
											<div slot="created_at" slot-scope="record">{{ record.created_at }} hrs.</div>
											<div slot="description" slot-scope="record">
												<a-tag :color="record.type == 'in' ? 'green' : 'red'">
													{{ record.id }}
												</a-tag>
											</div>
											<div slot="cash_in" slot-scope="record">
												<div v-if="record.type == 'in'">
													{{ numeral(record.amount).format('$0,0.00') }}
												</div>
												<div v-else>-</div>
											</div>
											<div slot="cash_out" slot-scope="record">
												<div v-if="record.type == 'out'">
													{{ numeral(record.amount).format('$0,0.00') }}
												</div>
												<div v-else>-</div>
											</div>
										</a-table>
									</div>
									<div class="col-12 text-right">
										<download-csv class="btn btn-default" :data="cashFlowList.transactions.filter((e) => e.payment_type == 'digital')" name="cashFlow_digital.csv"> DESCARGAR REPORTE </download-csv>
									</div>
								</div>
							</a-tab-pane> -->
						</a-tabs>
					</div>
				</div>
			</div>
		</div>
	</a-spin>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import moment from 'moment'
import { adminsList } from '@/constants'
import numeral from 'numeral'
import JsonCSV from 'vue-json-csv'

Vue.component('downloadCsv', JsonCSV)

const dateFormat = 'YYYY-MM-DD'

export default {
	name: 'IncomeExpensesView',
	data() {
		return {
			columns: [
				{
					title: 'Fecha',
					align: 'center',
					scopedSlots: { customRender: 'created_at' },
					width: '15%',
				},
				{
					title: 'Ticket / ODC',
					align: 'center',
					scopedSlots: { customRender: 'description' },
					width: '55%',
				},
				{
					title: 'Ingreso',
					align: 'center',
					scopedSlots: { customRender: 'cash_in' },
					width: '15%',
				},
				{
					title: 'Egreso',
					align: 'center',
					scopedSlots: { customRender: 'cash_out' },
					width: '15%',
				},
			],
			dateFormat,
			modalReceipt: {
				visible: false,
			},
			administradores: [1, 11, 12, 13, 8],
			defaultDate: [moment().format(dateFormat), moment().format(dateFormat)],
		}
	},
	computed: {
		...mapGetters('user', ['user', 'isUserAdmin']),
		...mapGetters('cashFlow', ['cashFlowList', 'spinnerLoader', 'spinnerLoaderLabel', 'generalCashFlow']),
		actualUser() {
			return !!adminsList.find((e) => e.id == this.user.user_id)
		},
	},
	beforeMount() {
		if (!this.administradores.includes(this.user.user_id)) {
			this.$router.replace('/dashboard')
		}
	},
	mounted() {
		this.initModule()
	},
	methods: {
		moment,
		numeral,
		initModule(date, dateString) {
			//
			let payload = {}
			if (dateString && dateString[0] == '' && dateString[1] == '') {
				this.defaultDate = [moment().format(dateFormat), moment().format(dateFormat)]
			} else {
				if (!!dateString && dateString.length) {
					payload = {
						startDate: dateString[0],
						endDate: dateString[1],
					}
				} else {
					payload = {
						startDate: moment(this.defaultDate[0]).format(dateFormat),
						endDate: moment(this.defaultDate[1]).format(dateFormat),
					}
				}
			}
			this.$store.dispatch('cashFlow/GET', payload)
		},
		onResetFilter(key) {
			this.$store.dispatch('purchaseOrders/RESET_ONE_FILTER', key)
			this.initModule()
		},
	},
}
</script>

<style lang="scss" module>
@import './style.module.scss';
</style>